import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const privacyContent = (
  <div className="privacy-policy-content">
    <p>
      Welcome, and thank you for visiting{" "}
      <Link to="/">https://sponsorship.wgbh.org/</Link> which is part of{" "}
      <a href="https://www.wgbh.org" target="_blank" rel="noreferrer">
        wgbh.org
      </a>{" "}
      and its associated sub-domains (collectively the “Site” or “Website”). As
      an extension and in support of our public media mission, we want your
      visit to be informative. We know you don't want the information you
      provide us shared indiscriminately. The privacy policy set forth here
      (“Privacy Policy”) explains what information Local Corporate Sponsorship,
      others at GBH, and our third-party vendors acting on our behalf to help
      deliver the services and operate and analyze use of the Site (collectively
      “we”) collect, what we do with the information, and what controls you have
      over the information collected. Please read it carefully, and if you have
      any questions or concerns, please{" "}
      <a
        href="https://www.wgbh.org/support/contact-wgbh"
        target="_blank"
        rel="noreferrer"
      >
        contact us
      </a>
      .
    </p>
    <p>
      This Privacy Policy refers solely to{" "}
      <Link to="/">sponsorship.wgbh.org/</Link>,{" "}
      <a href="https://www.wgbh.org" target="_blank" rel="noreferrer">
        wgbh.org
      </a>
      , and their associated sub-domains such as, but not limited to,{" "}
      <Link to="/television">sponsorship.wgbh.org/television</Link>,{" "}
      <Link to="/radio">sponsorship.wgbh.org/radio</Link>,{" "}
      <Link to="/digital">sponsorship.wgbh.org/digital</Link>, and{" "}
      <Link to="/additional-platforms">
        sponsorship.wgbh.org/additional-platforms
      </Link>
      .
    </p>
    <br />
    <h2>Third-Party Services, Content, and External Links</h2>
    <p>
      Please note that this Privacy Policy does not refer to or cover the
      privacy policies or terms of use of:
      <ol style={{ listStyle: "lower-alpha" }}>
        <li>
          third-party services, such as LinkedIn, ZoomInfo, and Google that you
          may be using to log into this Site or share information about your use
          of this Site, or about LCS or GBH;
        </li>
        <li>
          entities, such as PBS and NPR, whose links may appear on our sites; or
        </li>
        <li>
          entities whose content may appear on our Site (such as an embedded
          video player) or accessed by leaving our Site by clicking on a link.
          In either of these instances, the third party is in control of the
          content and its site. When you watch this third-party content through
          our Site or navigate to the third party’s site, you are subject to
          those third parties’ privacy policies and terms of use, including
          their collection and use of your information. Please consult those
          sites to learn more about their policies.
        </li>
      </ol>
    </p>
    <ol>
      <li>
        <h3>What Information We Collect About You</h3>
      </li>
      <p>
        We collect two types of information from you: (a) Personally
        identifiable information, includes personal information (such as your
        name, e-mail address, telephone number, and/or other identifying
        information) you voluntarily supply upon request when you register for
        our website, complete a survey, or provide your e-mail address; and (b)
        Non-personally identifiable information, for example IP Addresses and
        cookies, which includes tracking information collected as you navigate
        through our site. Uses of this information are discussed in Section 2
        below.
      </p>
      <ol style={{ listStyle: "lower-alpha" }}>
        <li>Personally Identifiable Information</li>
        <p>
          We will not collect personally identifiable information from you,
          other than what you supply to us on a voluntary basis upon request.
        </p>
        E-Mail and E-mail Newsletters:
        <p>
          On occasion, we may collect personal information from users in
          connection with optional email and e-mail newsletters. We will
          indicate which information is required and which is optional. Such
          information will be used as indicated at the time of collection.
        </p>
        Marketing and Promotional Communications:
        <p>
          On occasion, we may collect personal information from users in
          connection with optional marketing and promotional communications from
          GBH, PBS, or a public broadcasting station. We will indicate which
          information is required and which is optional. Such information will
          be used as indicated at the time of collection.
        </p>
        Third Party Advertising:
        <p>
          We use third-party advertising companies to serve ads when users visit
          our Site. These companies may use information (not including name,
          address, email address or telephone number) about visitors to the Site
          in order to provide advertisements about goods and services of
          interest to such visitors. These third parties may place their own
          cookies and other technologies in their ads which can collect
          information about your online activities on other sites in order to,
          among other things, perform analytics and serve you more relevant
          advertising on third-party sites. Some of these third parties
          participate in programs allowing you and others to opt out of targeted
          advertising. If you would like more information about this these
          programs and opt out possibilities, visit{" "}
          <a
            href="https://www.operative.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            www.operative.com/privacy-policy/
          </a>
          , the{" "}
          <a
            href="https://optout.networkadvertising.org/?c=1"
            target="_blank"
            rel="noreferrer"
          >
            Network Advertising Initiative
          </a>{" "}
          and the{" "}
          <a
            href="https://optout.aboutads.info/"
            target="_blank"
            rel="noreferrer"
          >
            Digital Advertising Alliance
          </a>
          .
        </p>
        <li>Non-Personally Identifiable Information</li>
        <p>
          In addition to information that you provide to us, we (again, this is
          GBH and third-party vendors) may also collect and store certain
          non-personally identifiable information automatically when you use the
          Site. This information helps us improve the experience you and other
          visitors have with the Site. Among the types of information we may
          collect are –
        </p>
        <p>Contact information, such as name or e-mail address;</p>
        <p>Geolocation information;</p>
        <p>Other demographic information, such as city, state, and zip code;</p>
        <p>Communications preferences;</p>
        <p>Search queries;</p>
        <p>
          Comments and other information posted through interactive online
          features;
        </p>
        <p>and Correspondence you send to us.</p>
        <p>
          We also may collect certain information automatically when you use the
          Services, such as:
        </p>
        <p>
          Your Internet Protocol (IP) address, browser type, operating system,
          and reference site domain, as well as related information such as the
          date on which you visit the Site. This information does not identify
          you personally and is only used in the aggregate;
        </p>
        <p>
          Sites you visited before and after visiting the Site, and pages you
          view and links you click on within the Site. This may include
          information about you from third-party sources. For example, we may
          receive information about you from third-party social media services
          when you choose to interact or connect with them through the Site.
        </p>
        <p>Standard server log information.</p>
        <p>
          As is common practice among web sites, we use “cookies,” web beacons,
          and other technologies. In general, cookies—the informational files
          that your web browser places on your computer when you visit a
          website—are used on this Site to track and analyze how you and other
          visitors use our Site. We use these cookies and other technologies in
          order to improve the Site, to identify the source of visitors and what
          pages you use while on our site, to offer visitors interactive and/or
          personalized features that would not be possible without the cookies,
          to recall specific information to save visitors time when they return
          to the Site, to provide our sponsors targeted sponsorship
          opportunities, to provide visitors customized sponsorship messages or
          list of customized videos of interest, and to identify usage and
          statistical trends. By looking at this traffic we can better
          understand our community, which allows us to improve our Site and to
          provide you with an optimal experience and better service. Our
          sponsorship service vendors, which serve sponsorship messages onto our
          site, and third-party vendors that measure and analyze the use of the
          Site for us, may also use their own cookies and technologies. At no
          time does the cookie reveal your name, address, or e-mail address
          (even if you have entered these in specific areas of the Site). The
          Site pages cannot extract any personally identifying information about
          you from what we receive via a cookie and your browser.
        </p>
        <p>
          You can set your browser preferences to refuse cookies or to alert you
          when cookies are being sent. However, some parts of the Site will not
          function properly if you do so.
        </p>
      </ol>
      <li>
        <h3>How We May Use Information Gathered on the Site</h3>
      </li>
      <p>
        Neither GBH, nor our third-party vendors acting on our behalf to help
        deliver the services and operate the Site, will willfully disclose any
        personally identifiable information about our online users to any
        non-affiliated third party without first receiving your permission,
        unless required by law.{" "}
      </p>
      <p>
        In terms of our own use of information, we may use your personally
        identifiable information for the activities described in the previous
        sections, for the activities described when you submitted the
        information, or for internal and marketing promotional purposes as
        further described in this policy.
      </p>
      <p>
        As mentioned, in certain cases, we contract with other companies to
        provide services on our behalf. These service providers maintain
        customer databases with e-mail addresses of Site visitors who have
        provided them to us by registering at our site, signing up for a
        newsletter or email, or e-mailing us questions about the service. We
        (which includes our service providers) will use these addresses to
        respond to the general purposes for which we collected the information,
        to provide services and to operate the Site, including to respond to
        inquiries about sponsorship opportunities; to process e-mail
        distribution; for list processing and analysis; for promotions
        management; to send information about GBH’s programs and services; or to
        correspond about a purchase or product offers. Our service providers
        have access to your personally identifiable information as necessary to
        provide certain services on our behalf. They are required to maintain
        the privacy of all such information in their possession or control and
        can only use the information on our behalf for the purpose that we have
        contracted them, for example sending you a newsletter. They are not
        authorized to use your information for any other purpose.
      </p>
      <p>
        If at any time you would like to check or update our record of your
        information, please let us know by emailing
        localcorporatesponsorship@wgbh.org.
      </p>
      <p>
        We will not sell, exchange, or lend e-mail addresses to any
        non-affiliated third party, without your permission. However, we or our
        service providers may release personally identifiable information if we
        believe in good faith that the law or legal process requires it, we have
        received a valid administrative request from a law enforcement agency,
        or such release is necessary to protect anyone’s rights, property, or
        safety.
      </p>
      <p>
        We reserve the right to send you e-mail relating to your account status.
        This includes order confirmations, renewal/expiration notices, notices
        of credit-card problems, other transactional e-mails and notifications
        about major changes to our Site and/or to our Privacy Policy. If you
        have registered for online discussions or other services, you may
        receive e-mail specific to your participation in those activities.
      </p>
      <p>
        GBH, with the assistance from time to time of its third-party service
        vendors, uses non-identifying aggregate information to analyze use of
        and better design the Site and to share with third parties in aggregate
        form only as appropriate. For example, we may tell a third party that a
        certain number of users accessed a particular video on our Site.
        However, we will not disclose any information that could be used to
        identify those users.
      </p>
      <li>
        <h3>Information Security</h3>
      </li>
      <p>
        GBH has in place what we believe to be appropriate physical, electronic,
        and managerial procedures to safeguard and secure the information we
        collect online. You should, however, keep in mind that no Internet or
        e-mail transmission is ever fully secure or error free. Because most
        e-mail is not encrypted, you should take special care in deciding what
        information you send to us via e-mail.
      </p>
      <li>
        <h3>Children’s Guidelines</h3>
      </li>
      <p>
        The sponsorship.wgbh.org site is meant only for GBH’s current and
        prospective sponsors (and we thank you for your interest!). It is not
        directed toward or intended for children of any age. GBH.org is a
        general audience site and does not knowingly collect or store personally
        identifiable information about children under the age of 13.
      </p>
      <p>
        Any portions of our Site that are directed to children under the age of
        13 (e.g., the kids section under the Kids and Family section) comply
        with COPPA (Children’s Online Privacy Protection Act) standards, which
        are in addition to wgbh.org policies. For complete guidelines on
        children’s information collection, please visit the{" "}
        <a
          href="https://www.wgbh.org/help/2018/04/30/kids-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          GBH KIDS PRIVACY POLICY
        </a>{" "}
        which is linked to the kids section of the Site.
      </p>
      <li>
        <h3>Corrections to Personal Data; Opting Out</h3>
      </li>
      <p>
        Please email us at localcorporatesponsorship@wgbh.org to a) correct or
        update any personal information in the GBH database that you state is
        erroneous, b) opt-out of future communications from GBH, or c) request
        GBH to make reasonable efforts to remove your personal information from
        the GBH online database, thereby canceling your profile, newsletter
        registration and other GBH registrations. This will not necessarily
        remove previous public comments and other User Materials submitted for
        public display on the Site. You should understand that it may be
        impossible to delete personal information entirely because of backups
        and records of deletions.
      </p>
      <li>
        <h3>California Residents</h3>
      </li>
      <p>
        Residents of the State of California may request a list of all third
        parties to whom we have disclosed certain information during the
        preceding year for the third parties' direct marketing purposes. If you
        are a California resident and want such a list, please send an e-mail to
        localcorporatesponsorship@wgbh.org. For all requests, you must put the
        statement "Your California Privacy Rights" in the subject matter of your
        request and include the name of the website or online service with
        respect to which you are requesting the information, as well as your
        name, company name, street address, city, state, and zip code.
      </p>
      <li>
        <h3>Acceptance of This Privacy Policy</h3>
      </li>
      <p>
        By using this Site, you signify your agreement to the terms and
        conditions of this Privacy Policy. If you do not agree to these terms
        and conditions, please do not use this Site. We reserve the right, at
        our sole discretion, to change, modify, add, or remove portions of this
        policy at any time. Please check this page periodically for any changes.
        Your continued use of this Site following the posting of any changes to
        these terms shall mean that you have accepted those changes. If you have
        any questions or concerns, please email us at
        localcorporatesponsorship@wgbh.org.
      </p>
      <li>
        <h3>Changes to This Privacy Policy</h3>
      </li>
      <p>
        Whenever this Privacy Policy for our Site changes, we will post the
        changes to the Site, and such changes will be effective immediately upon
        posting. If you do not agree to the changes, please do not continue to
        use the Site. Under certain circumstances, we may also elect to notify
        you of changes or updates to our Privacy Policy by additional means,
        such as posting a notice on the front page of our Site or sending you an
        e-mail if we have your e-mail address.
      </p>
      <li>
        <h3>Terms of Use</h3>
      </li>
      <p>
        Please also make sure to read GBH.org’s{" "}
        <a
          href="https://www.wgbh.org/help/2018/04/30/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>{" "}
        to understand the additional terms and conditions which apply to your
        use of our Site.
      </p>
    </ol>
  </div>
)

const Privacy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description="GBH Local Corporate Sponsorship Web Privacy Policy"
      />
      <div className="privacy-policy">
        <h2>WGBH Educational Foundation (“GBH”)</h2>
        <h1>LCS Web Privacy Policy</h1>
        <span>(As of March 14, 2022)</span>
        {privacyContent}
      </div>
    </Layout>
  )
}

export default Privacy
